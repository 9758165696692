@import '~antd/dist/antd.less';

@font-size-base: 16px;
@font-size-xlg: @font-size-base * 3;

@item-hover-bg: @primary-1;
@table-row-hover-bg: @item-hover-bg;
@red: @red-5;
@green: @green-5;
@success-color: @green;
@error-color: @red;
@highlight-color: @red;

@border-color-base: #a6a6a6;
@border-radius-base: 4px;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.ant-layout {
  padding: @padding-sm;
}

.ant-layout-content {
  min-height: 750px;
  max-width: 1300px;
  width: 90%;
  margin: 0 auto;
}

.positive {
  color: @success-color;
}

.negative {
  color: @error-color;
}

.normal {
  background-color: transparent;
  color: @primary-color;
}

.success {
  background-color: @success-color;
}

.exception {
  background-color: @error-color;
}

@media print {
  @page {
    size: A4;

    header {
      content: "Page " decimal(pageno), first(chapter);
      font-variant: small-caps
    }
    @top-left {
      content: "Hello";
    }

  }
}
