@import "node_modules/antd/lib/style/themes/default.less";
@import "./theme.less";

@banner-text-color: #5C6899;


.card-style() {
  background: linear-gradient(to bottom, #2469F4, #113BBF);
  box-shadow: 0px 16px 40px #0F2DA0;
  transition: transform .3s @ease-in-out, box-shadow .3s @ease-in-out;
  &:hover {
    box-shadow: 0px 20px 40px #0F2DA0;
  }
}

.ant-layout-header {
  height: 30px !important;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
  background: #001529;
}
