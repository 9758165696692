@import "./default.less";
.page {
  &-wrapper {
    width: 100%;
    will-change: transform;
  }
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  margin: auto;
  overflow: hidden;
  >h1 {
    margin: 132px auto 32px;
    font-size: 38px;
    line-height: 46px;
    color: #0d1a26;
    font-weight: 400;
    text-align: center;
  }
  >i {
    width: 64px;
    margin: auto;
    height: 2px;
    display: block;
    background: rgb(22, 217, 227);
    background: linear-gradient(to right, rgba(22, 217, 227, 1) 0%, rgba(22, 119, 227, 1) 100%);
  }
}

.banner-anim {
  &-arrow {
    opacity: 0;
    transition: opacity .3s @ease-out;
  }
  &:hover {
    .banner-anim-arrow {
      opacity: 1;
    }
  }
}

.page2 {
  background: url(https://gw.alipayobjects.com/zos/rmsportal/OciuRSqhtmcYCwJRJWSz.svg) no-repeat top;
  background-size: cover;
  min-height: 800px;
  text-align: left;
  &-content {
    margin-top: 58px;
  }
  h1 {
    color: #fff;
  }
  h4 {
    color: #fff;
  }
  h4 {
    font-size: 24px;
    line-height: 28px;
    text-shadow: 0 8px 12px #194EC1;
  }
  p {
    font-size: 12px;
    line-height: 20px;
    color: rgba(256, 256, 256, 0.7);
  }
  &-item {
    .card-style();
    max-width: 340px;
    width: 94%;
    border-radius: 4px;
    height: 384px;
    position: relative;
    margin: auto;
    &-wrapper {
      margin-bottom: 48px;
    }
    &.full {
      width: 100%;
      max-width: none;
      height: 500px;
    }
    &-bg {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;
    }
    &-desc {
      padding: 59px 46px 0;
      position: relative;
      z-index: 1;
    }
    &-links {
      margin-bottom: 58px;
      a {
        color: #fff;
        margin: 0 12px;
      }
    }
    .effect {
      height: 205px;
      background: url(https://gw.alipayobjects.com/zos/rmsportal/tGoBjrGoqlhsmCSSabgQ.svg) no-repeat;
      background-size: 100% 100%;
    }
  }
}

.page3 {
  min-height: 1080px;
  .page {
    .banner-wrapper {
      margin-top: 88px;
    }
    .banner-anim {
      height: 600px;
      video {
        height: 600px;
      }
      .rh5v-Volume_icon,
      .rh5v-Fullscreen_icon {
        padding: 0;
      }
    }
  }
}

.page4 {
  min-height: 664px;
  background: #f7f7f7;
  &-item {
    &-wrapper {
      margin-top: 96px;
    }
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 68px;
    img {
      max-width: 94%;
      display: inline-block;
    }
  }
}
